// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Dialog, Typography, RadioGroup, Radio, FormControl, Button, FormControlLabel, LinearProgress, DialogActions, MenuItem, Select, InputBase } from '@mui/material/';
import { Box, useTheme } from '@mui/system';
import SVG from 'react-inlinesvg';
import { useAppDispatch, useAppSelector } from 'Hooks';
import Dropzone from 'Components/dropzone';
import uploadKmlIcon from 'Assets/upload-kml-icon.svg';
import { FileUploadStartThunk } from 'Features/fileUpload/fileUploadThunk';
import { SetExpressAIUploadAction, SetDxfEpsgAction, HideUploadSingleFileDialogAction } from 'Features/fileUpload/fileUploadActions';
import stylesDef from './styles';

interface ComponentProps {
  fileExtension: any;
  adminOutputUpload: boolean;
  containerId?: string;
}

// { showDialog, file, containerId, fileExtension, adminOutputUpload, expressAIUpload, epsg, epsgList, FileUploadStartAction, SetExpressAIUploadAction, HideUploadKmlDialogAction, SetDxfEpsgAction }
const SingleFileUploadForm: React.FC<ComponentProps> = ({ fileExtension, adminOutputUpload, containerId }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const uploadFiles = useAppSelector((state) => state.fileUpload.uploadFiles);
  const expressAIUpload = useAppSelector((state) => state.fileUpload.expressAIUpload);
  const epsg = useAppSelector((state) => state.fileUpload.epsg);
  const epsgList = useAppSelector((state) => state.fileUpload.epsgList);
  const showUploadSingleFileDialog = useAppSelector((state) => state.fileUpload.showUploadSingleFileDialog);
  const file = uploadFiles[0];

  const [page, setPage] = useState(0);
  const [dxfFile, setDxfFile] = useState(null);

  useEffect(() => {
    dispatch(HideUploadSingleFileDialogAction());
    dispatch(SetDxfEpsgAction(epsgList[0]));
  }, []);

  const onUploadClick = async () => {
    await dispatch(FileUploadStartThunk([dxfFile]));
    onClose();
  };

  const onClose = (e = {}, reason = '') => {
    // disable backdropClick when client upload kml, so that the dialog will close when kmlCheck lambda is finished
    if (!_ADMIN_ && reason === 'backdropClick' && (fileExtension.includes('.kml') || fileExtension.includes('.kmz')) && uploadFiles.length) {
      return;
    }
    setDxfFile(null);
    setPage(0);
    dispatch(HideUploadSingleFileDialogAction());
  };

  const goToNextPage = () => {
    if (expressAIUpload) {
      setPage(2);
    } else {
      setPage(1);
    }
  };

  return (
    <Dialog
      open={showUploadSingleFileDialog}
      onClose={onClose}
      sx={{
        '& .MuiDialog-root': styles.dialogRoot,
        '& .MuiDialog-paper': styles.dialog,
        '& .MuiDialog-paperFullScreen': styles.paperFullscreen,
      }}
      fullScreen={!containerId}
      container={() => document.getElementById(containerId)}
    >
      <Box sx={styles.dropzoneWrapper}>
        {(adminOutputUpload && page === 0) && (
          <Box sx={styles.expressAIOptions}>
            <Typography variant="h5" sx={styles.dialogText}>Please indicate whether this file is Express AI:</Typography>
            <FormControl>
              <RadioGroup
                name="radio-buttons-group"
                value={expressAIUpload}
                onChange={() => dispatch(SetExpressAIUploadAction())}
              >
                <FormControlLabel sx={styles.radioButton} value control={<Radio />} label="Yes, it is Express AI" />
                <FormControlLabel sx={styles.radioButton} value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <DialogActions sx={styles.dialogActions}>
              <Button onClick={onClose} variant="text">Close</Button>
              <Button onClick={goToNextPage} variant="contained" color="primary">Continue</Button>
            </DialogActions>
          </Box>
        )}
        {(adminOutputUpload && page === 1) && (
          <Box sx={styles.expressAIOptions}>
            <Typography variant="h5" sx={styles.dialogText}>Select EPSG Code</Typography>
            <Typography variant="body1">
              Please select below the
              {' '}
              <span style={styles.bold}>
                EPSG
              </span>
              {' '}
              code for your file(s).
            </Typography>
            <FormControl>
              <Select
                variant="outlined"
                input={<InputBase />}
                sx={styles.dropdown}
                value={epsg}
                onChange={(e) => {
                  if (e.target.value) {
                    dispatch(SetDxfEpsgAction(e.target.value));
                  }
                }}
              >
                {epsgList.map((item: number) => (
                  <MenuItem key={item} value={item}>
                    EPSG:
                    {' '}
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions sx={styles.dialogActions}>
              <Button onClick={onClose} variant="text">Close</Button>
              <Button onClick={() => setPage(2)} variant="contained" color="primary">Upload Now</Button>
            </DialogActions>
          </Box>
        )}
        {(page === 2 || !adminOutputUpload) && (
          <>
            <Dropzone
              acceptedExtensions={fileExtension}
              onDropAccepted={async (acceptedFilesArray) => {
                const f = acceptedFilesArray[0];
                const filetype = f.name.substr(f.name.lastIndexOf('.') + 1);
                if (filetype === 'dxf' && expressAIUpload) {
                  setDxfFile(f);
                } else {
                  await dispatch(FileUploadStartThunk([f]));
                  onClose();
                }
              }}
            >
              {(getInputProps, open) => (
                <>
                  <Box sx={styles.uploadIconWrapper}>
                    <SVG src={uploadKmlIcon} title="Upload" css={styles.uploadFormIcon} />
                  </Box>
                  <br />
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <input {...getInputProps()} />
                  <Typography variant="h5" sx={styles.uploadFormText}>
                    Drag and drop to upload
                  </Typography>
                  <br />
                  <Typography variant="caption" sx={styles.uploadFormText}>
                    {'or '}
                    <button type="button" style={styles.openFileDialogLink} onClick={open}>browse</button>
                    {' to choose a file'}
                  </Typography>
                </>
              )}
            </Dropzone>
            {file && (
              <Box sx={styles.progressRow}>
                <Box>
                  <Typography variant="body2">{file.name}</Typography>
                </Box>
                <Typography variant="body2">
                  {file.progress.toFixed(2)}
                  {' '}
                  %
                  {' '}
                </Typography>
                <Box sx={styles.progress}>
                  <LinearProgress
                    color="primary"
                    variant="determinate"
                    value={file.progress}
                  />
                </Box>
              </Box>
            )}
            {(dxfFile && !file) && (
              <Box sx={styles.dxfFile}>
                <Typography variant="body2">{dxfFile.name}</Typography>
              </Box>
            )}
            {expressAIUpload && adminOutputUpload && (
              <DialogActions sx={styles.dialogActions}>
                <Button onClick={onClose} variant="text">Close</Button>
                <Button onClick={onUploadClick} variant="contained" color="primary">Upload Now</Button>
              </DialogActions>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default SingleFileUploadForm;
