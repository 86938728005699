// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Paper, Typography, Button, IconButton, Fade, CircularProgress, MenuItem, InputBase, Badge, Select, Tooltip, Collapse, Divider, List, ListItemIcon } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import downloadIcon from 'Assets/download-icon.png';
import aiIcon from 'Assets/ai.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneIcon from '@mui/icons-material/Done';
import autonmousIcon from 'Assets/autonomous.png';
import { Box, useTheme } from '@mui/system';
import KmlBufferDialog from 'Features/project/KmlBufferDialog';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { ClearDownloadFilesThunk, DeleteDeliverableFileThunk, DeleteDxfFileThunk, DeleteGeoDbFileThunk, DeleteOutputLasFileThunk, DeleteSurfaceFileThunk, DownloadDeliverableFileThunk, DownloadGDBWarningFileThunk, DownloadGeoDbFileThunk, DownloadLASThunk, DownloadPostgisDxfThunk, DownloadSurfaceFileThunk, EditOrderThunk, GenerateCadTemplateJsonFileThunk, GeneratePostgisExportFileThunk, GetOrdersThunk, SetDownloadFilesState, SetOrderIdThunk, SetOutputLas } from 'Features/order/orderThunk';
import SingleFileUploadForm from 'Components/FileUploadForm/SingleFileUploadForm';
import ConfirmDialog from 'Components/ConfirmDialog';
import ConfirmAmountDialog from 'Components/ConfirmAmountDialog';
import { ShowUploadSingleFileDialogAction } from 'Features/fileUpload/fileUploadActions';
import { projectTypes, standardBasePackages, utilitiesBasePackages } from 'Utils/constants';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import { GetProjectThunk } from 'Features/project/projectThunk';
import LayerSelectionDialog from './layerSelectionDialog';
import KmlFileFormatDialog from './KmlFileFormatDialog';
import GenerateGDBDialog from './GenerateGDBDialog';
import ErrorDialog from './ErrorDialog';
import stylesDef from './styles';
import CheckIcon from '@mui/icons-material/Check';
import { EditAddonsDialog } from './EditAddonsDialog';
import { OutOfCreditsDialog } from './OutOfCreditsDialog';
import { GetDeliverablesThunk } from 'Features/admin/adminThunk';

moment.tz.setDefault('America/New_York');
/**
 * Component renders contents of an order card for each order in the project
 */
export const OrderCard = ({ order, projectId, validFile }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  
  const { admin } = useAppSelector((state) => state);
  const downloadFileStatuses = useAppSelector((state) => state.order?.dxfFileStatus);
  const project = useAppSelector((state) => state.project.project);
  const telecom = useAppSelector((state) => state.admin.org?.telecom);
  const placedOrder = useAppSelector((state: RootState) => getOrders(state).placedOrder);

  const [uploadFileExtension, setUploadFileExtension] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showLayerSelection, setShowLayerSelection] = useState(false);
  const [bundleDeliverables, setBundleDeliverables] = useState([]);
  const [loadingDeliverables, setLoadingDeliverables] = useState(false);
  const [showEditAddonsDialog, setShowEditAddonsDialog] = useState(false);
  const [showOutOfCreditsDialog, setShowOutOfCreditsDialog] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [adminOutputUpload, setAdminOutputUpload] = useState(false);
  const [showConfirmDeleteXml, setShowConfirmDeleteXml] = useState(false);
  const [showConfirmDeleteGdb, setShowConfirmDeleteGdb] = useState(false);
  const [showConfirmDeleteDeliverable, setShowConfirmDeleteDeliverable] = useState(false);
  const [showConfirmDeleteDxf, setShowConfirmDeleteDxf] = useState(false);
  const [showConfirmDeleteLas, setShowConfirmDeleteLas] = useState(false);
  const [showKmlDownloadDialog, setShowKmlDownloadDialog] = useState(false);
  const [showGenerateGDBDialog, setShowGenerateGDBDialog] = useState(false);
  const [generateGDBLoading, setGenerateGDBLoading] = useState(false);
  const [generateGDBError, setGenerateGDBError] = useState(false);
  // tracking the loading status of the CAD template JSON generation
  const [generateCadTemplateJson, setGenerateCadTemplateJson] = useState(false);
  // tracking any errors that occur during the CAD template JSON generation
  const [generateCadTemplateJsonError, setGenerateCadTemplateJsonError] = useState(false);

  const [orderId, setOrderId] = useState('');
  const [xmlId, setXmlId] = useState('');
  const [deliverableId, setDeliverableId] = useState('');
  const [dxfId, setDxfId] = useState('');
  const [lasId, setLasId] = useState('');
  const [orgId, setOrgId] = useState('');
  const [editOrder, setEditOrder] = useState(false);
  const [projectTypeEdit, setProjectTypeEdit] = useState('');
  const [bundleEdit, setBundleEdit] = useState('');
  const [densityEdit, setDensityEdit] = useState('');
  const [deliverySpeedEdit, setDeliverySpeedEdit] = useState('');
  const [isEditSubmitting, setIsEditSubmitting] = useState(false);
  const [download, setDownload] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [basePackage, setBasePackage] = useState(project?.type === 'utilities' ? utilitiesBasePackages : standardBasePackages);

  const { creditAdjustments, bundleName, density, deliverySpeed } = order;

  useEffect(() => {
    if (projectTypeEdit === 'utilities') {
      setBasePackage(utilitiesBasePackages);
    } else if (projectTypeEdit === 'standard') {
      setBasePackage(standardBasePackages);
    } else {
      setBasePackage(project?.type === 'utilities' ? utilitiesBasePackages : standardBasePackages);
    }
  }, [projectTypeEdit]);

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    e.stopPropagation();
  };

  const onOkDeleteXml = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteXml(false);
    dispatch(DeleteSurfaceFileThunk(xmlId, orderId));
    e.stopPropagation();
  };
  const onOkDeleteDeliverable = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteDeliverable(false);
    dispatch(DeleteDeliverableFileThunk(deliverableId, orderId));
    e.stopPropagation();
  };

  const onOkDeleteGdb = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteGdb(false);
    dispatch(DeleteGeoDbFileThunk(xmlId, orderId));
    e.stopPropagation();
  };

  const onCancelGdb = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteGdb(false);
    setShowConfirmDeleteGdb(false);
    e.stopPropagation();
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    setShowConfirmDeleteXml(false);
    e.stopPropagation();
  };

  const onCancelDeleteDeliverable = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    setShowConfirmDeleteDeliverable(false);
    e.stopPropagation();
  };

  const onOkDeleteDxf = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteDxf(false);
    dispatch(DeleteDxfFileThunk(dxfId, orderId));
    e.stopPropagation();
  };

  const onCancelDxf = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    setShowConfirmDeleteDxf(false);
    e.stopPropagation();
  };

  const onOkDeleteLas = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteLas(false);
    if (lasId && orderId && projectId) {
      dispatch(DeleteOutputLasFileThunk(lasId, orderId, projectId));
    }
    e.stopPropagation();
  };

  const onCancelLas = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteLas(false);
    e.stopPropagation();
  };

  const PrepareOutputFile = async (oId: string, siteId: string) => {
    await dispatch(SetDownloadFilesState(siteId));
    await dispatch(DownloadPostgisDxfThunk(oId, siteId, true));
  };

  const DownloadOutputFile = async (siteId: string) => {
    window.open(downloadFileStatuses.find((f) => f.siteId === siteId)?.url, '_self');
    await dispatch(ClearDownloadFilesThunk(siteId));
  };

  const onKmlUploadClick = (oId: string) => {
    dispatch(SetOrderIdThunk(oId));
    setUploadFileExtension(['.kml', '.kmz']);
    setAdminOutputUpload(false);
    setShowUploadDialog(true);
  };

  const onDxfUploadClick = (oId: string) => {
    dispatch(SetOrderIdThunk(oId));
    setUploadFileExtension(['.dxf', '.laz', '.xml', '.zip', '.shp', '.dwg', '.dgn', '.dem', '.dtm']);
    setAdminOutputUpload(true);
    dispatch(SetOutputLas(true));
    setShowUploadDialog(true);
  };

  useEffect(() => {
    if (showUploadDialog) {
      dispatch(ShowUploadSingleFileDialogAction());
      setShowUploadDialog(false);
    }
  }, [showUploadDialog]);

  const ButtonFunction = (oId: string, cadFile: any) => {
    const siteId = cadFile._id;
    if (downloadFileStatuses.some((obj) => (obj.siteId === siteId && obj.fileReadyStatus.dxfFile === 1))) {
      setDownload(false);
      DownloadOutputFile(siteId);
    } else {
      setDownload(true);
      PrepareOutputFile(oId, siteId);
    }
  };

  const resetOrderEdits = () => {
    setBundleEdit('');
    setDensityEdit('');
    setDeliverySpeedEdit('');
  };

  const editOrderClick = (oid: string) => {
    if (oid !== orderId) {
      resetOrderEdits();
    }
    setOrderId(oid);
    setEditOrder(true);
  };

  const cancelEditOrderClick = () => {
    setOrderId('');
    resetOrderEdits();
    setEditOrder(false);
  };

  const editOrderUpdateClick = async () => {
    setIsEditSubmitting(true);
    await dispatch(EditOrderThunk({
      orderId,
      bundleName: bundleEdit,
      density: densityEdit,
      deliverySpeed: deliverySpeedEdit,
      projectType: projectTypeEdit,
    }));
    await dispatch(GetProjectThunk());
    await dispatch(GetOrdersThunk(projectId));
    setOrderId('');
    resetOrderEdits();
    setEditOrder(false);
    setIsEditSubmitting(false);
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const onGenerateGDBClick = async () => {
    if (order?.cadFiles.length > 1) {
      setShowGenerateGDBDialog(true);
    } else if (order?.cadFiles[0]?._id) {
      setGenerateGDBLoading(true);
      const result = await dispatch(GeneratePostgisExportFileThunk(order?.cadFiles[0]?._id, '.gdb'));
      if (result?.error) {
        setGenerateGDBError(true);
      }
      setGenerateGDBLoading(false);
    }
  };
  // Function to handle the generation of cad temmplate json file
  const onDownloadCadTemplateJson = async () => {
    if (order?.orderStatus > 0) {
      setGenerateCadTemplateJson(true);
      const result = await dispatch(GenerateCadTemplateJsonFileThunk(order?.cadTemplate));
      if (result?.error) {
        setGenerateCadTemplateJsonError(true);
      }
      setGenerateCadTemplateJson(false);
    }
  };
  
  const onEditAddonsClick = async () => {
    setLoadingDeliverables(true);
    setShowEditAddonsDialog(true);
    const response = await dispatch(GetDeliverablesThunk(order._id, projectId));
    setBundleDeliverables(response);
    setLoadingDeliverables(false);
  };

  return (
    <>
      <Paper sx={styles.card}>
      {/* display order submitter only if order status is greater than 0
       if order is submitted by an AirWorks admin, display the admin email */}
      <Box sx={styles.orderDetails}>
      <Typography variant="h6" sx={styles.itemWrapper}>Order Details </Typography>
      { <Typography sx={styles.itemWrapper}>Submitter: { order.submittedByAwAdmin || order.ownerEmail || 'N/A'}</Typography> }
      </Box>
      <Divider sx={styles.sectionDivider} />
        <Typography sx={styles.itemWrapper}>{`${order.ownerOrganization}/${projectId}/${order._id}`}</Typography>
        <Box sx={styles.orderInfoWrapper}>
          <Box>
            <Box sx={styles.inputTitleWrapper}>
              <Typography variant="h4">Input Files</Typography>
              <Button onClick={() => onKmlUploadClick(order._id)} color="primary">Upload Files</Button>
            </Box>
            <Box sx={styles.cadFileList}>
              {order.boundaryFile && <Typography>{order.boundaryFile.split('/').pop()}</Typography>}
              {order.boundaryFile && (
                <IconButton onClick={() => setShowKmlDownloadDialog(true)}>
                  <img src={downloadIcon} alt="Download .kml" />
                </IconButton>
              )}
            </Box>
            <Box sx={styles.inputTitleWrapper}>
              <Typography variant="h4">Output Files</Typography>
              <Button disabled={!validFile} onClick={() => { onDxfUploadClick(order._id); }} color="primary">Upload Files</Button>
            </Box>
            <Box sx={styles.outputFilesWrapper}>
              {(order.outputLasFiles && order.outputLasFiles.length > 0) && order.outputLasFiles.map((file) => (
                <Box sx={styles.cadFileList} key={file._id}>
                  <Typography>{file.filename && file.filename.split('/').pop()}</Typography>
                  <Box>
                    <IconButton onClick={() => {
                      setShowConfirmDeleteLas(true);
                      setLasId(file._id);
                      setOrderId(order._id);
                    }}
                    >
                      <ClearIcon sx={styles.deleteIcon} />
                    </IconButton>
                    <IconButton onClick={() => dispatch(DownloadLASThunk(order._id, file.filename.split('/').pop()))}>
                      <img src={downloadIcon} alt="Download .las" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              {order?.surfaceFiles?.map((file) => (
                <Box sx={styles.cadFileList} key={file._id}>
                  <Typography>{file.filename}</Typography>
                  <Box>
                    <IconButton onClick={() => {
                      setShowConfirmDeleteXml(true);
                      setXmlId(file._id);
                      setOrderId(order._id);
                    }}
                    >
                      <ClearIcon sx={styles.deleteIcon} />
                    </IconButton>
                    <IconButton onClick={() => dispatch(DownloadSurfaceFileThunk(file._id, order._id))}>
                      <img src={downloadIcon} alt="Download .xml" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              {order?.deliverableFiles?.map((file) => (
                <Box sx={styles.cadFileList} key={file._id}>
                  <Typography>{file.filename}</Typography>
                  <Box>
                    <IconButton onClick={() => {
                      setShowConfirmDeleteDeliverable(true);
                      setDeliverableId(file._id);
                      setOrderId(order._id);
                    }}
                    >
                      <ClearIcon sx={styles.deleteIcon} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        dispatch(DownloadDeliverableFileThunk(file._id, order._id));
                      }}
                    >
                      <img src={downloadIcon} alt={`Download (${file.fileType})`} />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              {order?.geoDbFiles?.map((file) => (
                <Box key={file._id}>
                  <Box sx={styles.cadFileList}>
                    <Typography>{file.filename}</Typography>
                    <Box>
                      <IconButton onClick={() => {
                        setShowConfirmDeleteGdb(true);
                        setXmlId(file._id);
                        setOrderId(order._id);
                      }}
                      >
                        <ClearIcon sx={styles.deleteIcon} />
                      </IconButton>
                      <IconButton onClick={() => dispatch(DownloadGeoDbFileThunk(file._id, order._id))}>
                        <img src={downloadIcon} alt="Download .zip" />
                      </IconButton>
                    </Box>
                  </Box>
                  {file.warningFileName && (
                    <Box sx={styles.cadFileList}>
                      <Box sx={styles.warningFile}>
                        <InfoIcon sx={styles.infoIcon} />
                        <Typography>{file.warningFileName}</Typography>
                      </Box>
                      <Box>
                        <IconButton onClick={() => dispatch(DownloadGDBWarningFileThunk(file._id))}>
                          <img src={downloadIcon} alt="Download warning.txt" />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
              {order?.cadFiles?.map((file) => (
                <Box sx={styles.cadFileList} key={file._id}>
                  <Box sx={styles.cadFileName}>
                    {file.expressAIUpload && (
                      <Tooltip title="Express AI" arrow placement="top">
                        <img src={aiIcon} alt="Express AI Upload" />
                      </Tooltip>
                    )}
                    {file.autonomous && (
                      <Tooltip title="Autonomous" arrow placement="top">
                        <img src={autonmousIcon} alt="Autonomous" />
                      </Tooltip>
                    )}
                    <Typography>{file.name}</Typography>
                  </Box>
                  {/* TODO - move this logic outside return - Ishan 06/23/2021 */}
                  {downloadFileStatuses.some((obj) => (obj.siteId === file._id && obj.fileReadyStatus.dxfFile === 1) && download) ? (
                    <Badge color="primary" overlap="circle" variant="dot">
                      <IconButton onClick={() => ButtonFunction(order._id, file)}>
                        <img src={downloadIcon} alt="Download .dxf" />
                      </IconButton>
                    </Badge>
                  ) :
                    (downloadFileStatuses.some((obj) => (
                      obj.siteId === file._id && obj.fileReadyStatus.dxfFile === 0)) && download) ?
                      <CircularProgress size={24} sx={styles.downloadProgress} /> : (
                          <Box>
                          <IconButton onClick={() => {
                            setShowConfirmDeleteDxf(true);
                            setDxfId(file._id);
                            setOrderId(order._id);
                          }}
                            >
                              <ClearIcon sx={styles.deleteIcon} />
                            </IconButton>
                          <IconButton onClick={() => ButtonFunction(order._id, file)}>
                              <img src={downloadIcon} alt="Download .dxf" />
                            </IconButton>
                        </Box>
                      )}
                </Box>
              ))}
              <Box>
                {(order?.cadFiles?.some((file) => (file.expressAIUpload === true && !file.expressAIEmailSent))) && (
                  <Button
                    onClick={() => { setShowLayerSelection(true); }}
                    color="primary"
                  >
                    SEND EMAIL
                  </Button>
                )}
                {(order?.cadFiles?.some((file) => file.expressAIEmailSent)) &&
                  <Typography variant="h5" sx={styles.emailText}>EMAIL SENT</Typography>}
              </Box>
            </Box>
            <Box sx={styles.buttonContainer}>
              {
                generateGDBLoading ?
                  <CircularProgress size={24} /> :
                  <Button disabled={!order?.cadFiles.length} onClick={onGenerateGDBClick} color="primary" sx={styles.gdbButton}>Generate GDB</Button>
              }
            </Box>
            {order?.cadTemplateFile?.retainDuplicates && (
              <Box sx={styles.duplicateContainer}>
                <InfoIcon sx={styles.infoIcon} />
                <Typography>Do not run “de-duplicator”</Typography>
              </Box>
            )}
            <Box>
              {
               generateCadTemplateJson ?
               <CircularProgress size={24} /> :
                <Button disabled={order?.orderStatus === 0} onClick={onDownloadCadTemplateJson} color="primary" sx={styles.gdbButton}>Download Template JSON + DXF</Button>
              }
            </Box>
            <Box sx={styles.inputTitleWrapper}>
              <Typography variant='h4'>Layer Deliverables</Typography>
              <Button 
                variant="text" 
                onClick={onEditAddonsClick}
                color="primary"
                disabled={order?.orderStatus === 0 || order?.orderStatus === 7 || order?.orderStatus === 8}
              >
                Edit Add-ons
              </Button>
            </Box>
            <Box>
              {
                order?.deliverables?.map((deliverable:any) => 
                  <List sx={styles.bundleListItem} key={deliverable._id}>
                    <ListItemIcon sx={styles.listItemIcon}>
                      <CheckIcon sx={styles.finishedIcon} />
                    </ListItemIcon>
                    <Typography>{deliverable.name}</Typography>
                  </List>
                )
              }
            </Box>
          </Box>
          <Box>
            <Box sx={styles.gridChild}>
              <Typography sx={styles.itemWrapper}>Date Created</Typography>
              {order.createdAt ? <Typography>{moment(order.createdAt).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography sx={styles.itemWrapper}>Date Processed</Typography>
              {order.orderDate ? <Typography>{moment(order.orderDate).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography sx={styles.itemWrapper}>Est. Delivery Estimate</Typography>
              {order.deliveryEstimate ? <Typography>{moment(order.deliveryEstimate).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography sx={styles.itemWrapper}>Delivery Date</Typography>
              {order.deliveryDate ? <Typography>{moment(order.deliveryDate).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography sx={styles.itemWrapper}>Kickoff Date</Typography>
              {order.anticipatedKickoffDate ? <Typography>{moment.utc(order.anticipatedKickoffDate).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography sx={styles.itemWrapper}>Order Acres</Typography>
              <Typography>{ order.acreage || 'N/A'}</Typography>

              <Typography sx={styles.itemWrapper}>Processing Area Length</Typography>
              <Typography>{order.kmlLength || 'N/A'}</Typography>

              
              <Typography sx={styles.itemWrapper}>Order Linear Feet</Typography>
              <Typography>{order.orderLinearFeet || 'N/A'}</Typography>

              <Typography sx={styles.itemWrapper}>Base Package</Typography>
              {editOrder ? (
                <Select
                  variant="outlined"
                  input={<InputBase style={{ width: '100px' }} />}
                  value={bundleEdit || bundleName}
                  onChange={(e) => {
                    if (e.target.value === 'Topo') {
                      setDensityEdit('normal');
                    }
                    setBundleEdit(e.target.value);
                  }}
                >
                  {
                    basePackage?.map((bundle) => <MenuItem value={bundle}>{bundle}</MenuItem>)
                  }
                </Select>
              ) :
                <Typography>{bundleName || 'N/A'}</Typography>}
              <Typography sx={styles.itemWrapper}>Density</Typography>
              {(editOrder) && (!bundleEdit ? bundleName !== 'Topo' : bundleEdit !== 'Topo') ? (
                <Select
                  variant="outlined"
                  input={<InputBase style={{ width: '100px' }} />}
                  value={densityEdit || density}
                  onChange={(e) => setDensityEdit(e.target.value)}
                  disabled={order.orderStatus === 0}
                >
                  {
                    ['normal', 'low'].map((density) => <MenuItem value={density}>{density}</MenuItem>)
                  }
                </Select>
              ) :
                <Typography>{(!densityEdit ? density : densityEdit)}</Typography>}
              <Typography sx={styles.itemWrapper}>Delivery Speed</Typography>
              {editOrder ? (
                <Select
                  variant="outlined"
                  input={<InputBase style={{ width: '100px' }} />}
                  value={deliverySpeedEdit || deliverySpeed}
                  onChange={(e) => setDeliverySpeedEdit(e.target.value)}
                  disabled={order.orderStatus === 0}
                >
                  {
                    ['normal', 'expedited', 'super_expedited'].map((speed) => <MenuItem value={speed}>{speed?.split('_').join(' ')}</MenuItem>)
                  }
                </Select>
              ) :
                <Typography>{deliverySpeed?.split('_').join(' ') || 'N/A'}</Typography>}
              <Typography sx={styles.itemWrapper}>Project Type</Typography>
              {
                editOrder && telecom ? (
                  <Select
                    variant="outlined"
                    input={<InputBase style={{ width: '100px' }} />}
                    value={projectTypeEdit || project.type}
                    onChange={(e) => setProjectTypeEdit(e.target.value)}
                    disabled={order.orderStatus > 0 || !!placedOrder}
                  >
                    {
                      projectTypes.map((type) => <MenuItem key={type} value={type} sx={styles.projectType}>{type}</MenuItem>)
                    }
                  </Select>
                ) :
                  <Typography sx={styles.projectType}>{project.type || 'standard'}</Typography>
              }
              <Typography sx={styles.itemWrapper}>Initial Order Credits</Typography>
              <Typography>{order.initialCredits ? order.initialCredits.toFixed(2) : 'N/A'}</Typography>
            </Box>
            <Box sx={styles.layersAndDeliverablesWrapper}>
              {order.addOns?.length > 0 && (
                <>
                  <Box sx={styles.sectionHeader}>
                    <Typography
                      sx={styles.layersAndDeliverablesTitle}
                      variant="h4"
                    >
                      LAYERS & DELIVERABLES
                    </Typography>
                    {isExpanded ? (
                      <Button onClick={handleToggle}>
                        <ArrowDropUpIcon sx={styles.arrowIcon} />
                      </Button>
                    ) : (
                      <Button onClick={handleToggle}>
                        <ArrowDropDownIcon sx={styles.arrowIcon} />
                      </Button>
                    )}
                  </Box>
                  <Divider sx={styles.sectionDivider} />
                  <Collapse in={isExpanded}>
                    <Box>
                      <Box>
                        {order.addOns.map((addOn, i) => {
                          if (addOn.type === 'deliverable') {
                            return (
                              <Box key={addOn.name} sx={styles.addOnItem}>
                                <DoneIcon sx={styles.doneIcon} />
                                <Typography sx={styles.addOnName}>
                                  {addOn.name}
                                </Typography>
                                <Typography sx={styles.addOnAcres}>
                                  {addOn.acres ? `${addOn.acres.toFixed(2)} credits` : 'N/A credits'}
                                </Typography>
                              </Box>
                            );
                          }
                          return null;
                        })}
                      </Box>
                      <Divider sx={styles.sectionDivider} />
                      <Box>
                        {order.addOns.map((addOn, i) => {
                          if (addOn.type === 'layer') {
                            return (
                              <Box key={addOn.name} sx={styles.addOnItem}>
                                <DoneIcon sx={styles.doneIcon} />
                                <Typography sx={styles.addOnName}>
                                  {addOn.name}
                                </Typography>
                                <Typography sx={styles.addOnAcres}>
                                  {addOn.acres ? `${addOn.acres.toFixed(2)} credits` : 'N/A credits'}
                                </Typography>
                              </Box>
                            );
                          }
                          return null;
                        })}
                      </Box>
                    </Box>
                  </Collapse>
                </>
              )}
              <Box sx={styles.addOnItem}>
                <Typography variant="h5" sx={styles.totalCreditTitle}>
                  Total Credits Charged
                </Typography>
                {editOrder ? (
                  <Typography sx={styles.totalCredits}>-</Typography>
                ) : (
                  <Typography sx={styles.totalCredits}>
                    {order.creditAcreage ? order.creditAcreage.toFixed(2) : 'N/A'}
                    {' '}
                    credits
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={styles.gridChild}>
              <Typography sx={styles.itemWrapper}>
                Adjusted Credits
              </Typography>
              <Typography>
                { (order.adjustedAcres ?? 0).toFixed(2) }
              </Typography>
            </Box>
            {creditAdjustments?.length > 0 && (
              <ul sx={styles.adjustmentList}>
                {creditAdjustments.map((a, i) => (
                  <li key={a._id}>
                    <Box sx={styles.creditAdjustmentItemWrapper}>
                      <p sx={styles.adjustmentNumber}>
                        {a.adjustment >= 0 ? '+' : ''}
                        {a.adjustment}
                      </p>
                      <p>{a.reason}</p>
                    </Box>
                  </li>
                ))}
              </ul>
            )}
            {editOrder ? (
              <Box sx={styles.buttonGroup}>
                <Button sx={styles.editOrderButtonWrapper} onClick={cancelEditOrderClick} color="primary">Cancel</Button>
                <Button
                  sx={styles.editOrderButtonWrapper}
                  onClick={editOrderUpdateClick}
                  disabled={isEditSubmitting || (!bundleEdit && !densityEdit && !deliverySpeedEdit) ||
                    ((bundleEdit !== '' || densityEdit !== '' || deliverySpeedEdit !== '') && (bundleEdit === bundleName) && (densityEdit === density) && (deliverySpeedEdit === deliverySpeed))}
                  color="primary"
                >
                  <Fade in={isEditSubmitting}>
                    <CircularProgress size={24} sx={styles.buttonProgress} />
                  </Fade>
                  <Fade in={!isEditSubmitting}>
                    <span>Update</span>
                  </Fade>
                </Button>
              </Box>
            ) : (
              <Button
                sx={styles.editOrderButtonWrapper}
                onClick={() => editOrderClick(order._id)}
                disabled={!telecom && order.orderStatus === 0}
                color="primary"
              >
                Edit Order
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
      <LayerSelectionDialog
        showDialog={showLayerSelection}
        onCancel={() => { setShowLayerSelection(false); }}
        orderId={order._id}
      />
      <KmlFileFormatDialog
        order={order}
        showDialog={showKmlDownloadDialog}
        onCancel={() => setShowKmlDownloadDialog(false)}
      />
      {uploadFileExtension && <SingleFileUploadForm fileExtension={uploadFileExtension} adminOutputUpload={adminOutputUpload} />}
      <ConfirmDialog
        showDialog={showConfirmDeleteXml}
        onOk={onOkDeleteXml}
        onCancel={onCancel}
        contentText="Selected surface file will be deleted"
      />
      <ConfirmDialog
        showDialog={showConfirmDeleteDeliverable}
        onOk={onOkDeleteDeliverable}
        onCancel={onCancelDeleteDeliverable}
        contentText="Selected deliverable file will be deleted"
      />
      <ConfirmDialog
        showDialog={showConfirmDeleteDxf}
        onOk={onOkDeleteDxf}
        onCancel={onCancelDxf}
        contentText="Selected dxf file will be deleted"
      />
      <ConfirmDialog
        showDialog={showConfirmDeleteGdb}
        onOk={onOkDeleteGdb}
        onCancel={onCancelGdb}
        contentText="Selected geodatabase file will be deleted"
      />
      <ConfirmDialog
        showDialog={showConfirmDeleteLas}
        onOk={onOkDeleteLas}
        onCancel={onCancelLas}
        contentText="Selected las file will be deleted"
      />
      <ConfirmAmountDialog
        showDialog={showConfirmDialog}
        onOk={onOk}
        onCancel={onCancel}
        confirmAmount={+(order.creditAcreage.toFixed(2))}
        orderId={orderId}
        orgId={orgId}
        projectId={projectId}
      />
      <EditAddonsDialog
        open={showEditAddonsDialog}
        setOpen={setShowEditAddonsDialog}
        orderId={order?._id}
        projectId={projectId}
        orderAcreage={order?.acreage}
        orderStatus={order?.orderStatus}
        bundleDeliverables={bundleDeliverables}
        loadingDeliverables={loadingDeliverables}
        setShowOutOfCreditsDialog={setShowOutOfCreditsDialog}
      />
      <OutOfCreditsDialog open={showOutOfCreditsDialog} setOpen={setShowOutOfCreditsDialog}/>
      <KmlBufferDialog />
      <GenerateGDBDialog showDialog={showGenerateGDBDialog} cadFiles={order?.cadFiles} onCancel={() => setShowGenerateGDBDialog(false)} setError={setGenerateGDBError} />
      <ErrorDialog message='Unable to generate GDB please contact Algorithms for further troubleshooting.' showDialog={generateGDBError} onCancel={() => setGenerateGDBError(false)} />
      <ErrorDialog message='Unable to generate Cad Template Json please contact Webapp team for further troubleshooting.' showDialog={generateCadTemplateJsonError} onCancel={() => setGenerateCadTemplateJsonError(false)} />
    </>
  );
};

export default OrderCard;
