// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material/';
import { useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import { SetLineStringUploadAction, SetMultipleGeometriesUploadAction } from 'Features/kml/kmlActions';
import { SetShowKmzDetectedDialogAction, ShowUploadSingleFileDialogAction } from 'Features/fileUpload/fileUploadActions';
import { Clear } from 'Features/map/mapDrawThunk';
import stylesDef from './styles';

export const KmzDialog = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const { showKmzDetectedDialog } = useAppSelector((state: RootState) => state.fileUpload);

  const onClose = () => {
    dispatch(SetShowKmzDetectedDialogAction(false));
  };

  const onUploadClick = () => {
    dispatch(Clear());
    dispatch(SetShowKmzDetectedDialogAction(false));
    dispatch(SetMultipleGeometriesUploadAction(false));
    dispatch(SetLineStringUploadAction(false));
    dispatch(ShowUploadSingleFileDialogAction());
  };

  const onProceedClick = () => {
    dispatch(SetShowKmzDetectedDialogAction(false));
  };

  return (
    <Dialog
      open={showKmzDetectedDialog}
      onClose={onClose}
    >
      <Typography variant="h3" sx={styles.title}>KMZ Detected</Typography>
      <DialogContent>
        <Typography>
          We’ve detected you’re attempting to upload a KMZ. We may be able display the geometries within the KMZ but not any other metadata.
        </Typography>
      </DialogContent>
      <DialogActions sx={styles.buttons}>
        <Button sx={styles.cancelButton} onClick={onUploadClick}>UPLOAD A KML</Button>
        <Button sx={styles.okButton} onClick={onProceedClick}>PROCEED</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KmzDialog;
