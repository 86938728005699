// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import moment from 'moment-business-days';
import 'moment-timezone';

// Get the deliveryEstimate date based on time of placing the order and deliveryEstimateHours

// Configure holidays for 2025. Not sure how to make it dynamic so that it works for any year?
const newYearsDay = '01-01-2025';
const mlkDay = '01-20-2025';
const memorialDay = '05-26-2025';
const independenceDay = '07-04-2025';
const laborDay = '09-01-2025';
const thanksgiving = '11-27-2025';
const dayAfterThanksgiving = '11-28-2025';
const christmasEve = '12-24-2025';
const christmas = '12-25-2025';

moment.updateLocale('us', {
  holidays: [newYearsDay, mlkDay, memorialDay, independenceDay, laborDay, thanksgiving, dayAfterThanksgiving, christmasEve, christmas],
  holidayFormat: 'MM-DD-YYYY',
});

// Configure working days of a week
moment.updateLocale('us', {
  workingWeekdays: [1, 2, 3, 4, 5],
});

const checkWorkingHours = () => {
  const dateNow = moment(new Date());
  // Convert user's time to est time
  // moment tz will take DST into consideration
  const estNow = moment.tz(dateNow, 'America/New_York');

  // Configure working hours in EST
  const estDate = estNow.format('YYYY-MM-DD');
  const startTime = moment.tz(`${estDate} 00:00:00`, 'America/New_York');
  const endTime = moment.tz(`${estDate} 17:00:00`, 'America/New_York');

  // Check if an order is placed between 12am and 5pm EST
  const orderDuringWorkingHours = estNow.isBetween(startTime, endTime);

  return {
    orderDuringWorkingHours,
    estNow,
  };
};

export const projByProjDollarPerAcre = 85;

export const deliveryEstimate = (days: number) => {
  const { orderDuringWorkingHours, estNow } = checkWorkingHours();
  const workingDays = Math.ceil(days);

  const deliveryEstimateDays = orderDuringWorkingHours ? workingDays : workingDays + 1;

  const deliveryEstimateEst = moment(estNow).businessAdd(deliveryEstimateDays);
  const deliveryEstimateLocal = moment(deliveryEstimateEst).format();
  return deliveryEstimateLocal;
};

// this determines the acreCost of a deliverable
export const deliverableCost = (acreFactor: number, orderAcreage: number): number => {
  if (orderAcreage === 0) {
    return 0;
  }
  // The add-ons Breaklines and Breaklines (2D + Topo) have acreFactor values greater than 1
  // and their deliverableCost is calculated by multiplying the acreFactor with the acreage
  if (acreFactor !== 1) {
    return +(acreFactor * orderAcreage).toFixed(2);
  }
  return +acreFactor;
};

export const deliveryBusinessDays = (days: number) => {
  const { orderDuringWorkingHours } = checkWorkingHours();
  const workingDays = Math.ceil(days);

  const deliveryEstimateDays = orderDuringWorkingHours ? workingDays : workingDays + 1;

  return deliveryEstimateDays;
};

// format number to string with thousands separator and 2 decimals
export const formatValue = (value: number) => (value || 0).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default deliveryEstimate;
