// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  FileUploadStartAction,
  FileUploadUpdateAction,
  FileUploadCancelAction,
  FileUploadInitAction,
  SetFileUploadSizeErrorAction,
  SetImageryCaptureDateAction,
  SetExpressAIUploadAction,
  SetDxfEpsgAction,
  ShowUploadSingleFileDialogAction,
  HideUploadSingleFileDialogAction,
  ShowOpsTrainingDialogAction,
  HideOpsTrainingDialogAction,
  SetEpsgListAction,
  SendExpressAIEmailStartAction,
  SendExpressAIEmailSuccessAction,
  SendExpressAIEmailFailureAction,
  SetShowKmzDetectedDialogAction,
} from './fileUploadActions';

const initialState: IFileUploadState = {
  uploadFiles: [],
  uploadFileSizeError: false,
  uploading: false,
  uploadSuccess: false,
  expressAIUpload: false,
  emailLoading: false,
  epsg: null,
  epsgList: [],
  imageryCaptureDate: null,
  showUploadSingleFileDialog: false,
  showOpsTrainingDialog: false,
  showKmzDetectedDialog: false,
};

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ShowUploadSingleFileDialogAction, (state) => {
        state.showUploadSingleFileDialog = true;
      })
      .addCase(HideUploadSingleFileDialogAction, (state) => {
        state.showUploadSingleFileDialog = false;
      })
      .addCase(ShowOpsTrainingDialogAction, (state) => {
        state.showOpsTrainingDialog = true;
      })
      .addCase(HideOpsTrainingDialogAction, (state) => {
        state.showOpsTrainingDialog = false;
      })
      .addCase(FileUploadStartAction, (state, action) => {
        state.uploadFiles.push(action.payload);
      })
      .addCase(FileUploadUpdateAction, (state, action) => {
        const index = state.uploadFiles.findIndex((f) => f.id === action.payload.id);
        if (index >= 0) {
          if (!state.uploadFiles[index].fileId) {
            Object.assign(state.uploadFiles[index], action.payload);
          } else {
            state.uploadFiles[index].progress = action.payload.progress;
          }
        }
      })
      .addCase(FileUploadCancelAction, (state, action) => {
        state.uploadFiles = state.uploadFiles.filter((f) => f.id !== action.payload);
      })
      .addCase(FileUploadInitAction, (state) => {
        state.uploadFiles = [];
        state.uploading = initialState.uploading;
        state.uploadSuccess = initialState.uploadSuccess;
      })
      .addCase(SetFileUploadSizeErrorAction, (state, action) => {
        state.uploadFileSizeError = action.payload;
      })
      .addCase(SetImageryCaptureDateAction, (state, action) => {
        state.imageryCaptureDate = action.payload;
      })
      .addCase(SetExpressAIUploadAction, (state) => {
        state.expressAIUpload = !state.expressAIUpload;
      })
      .addCase(SendExpressAIEmailStartAction, (draft) => { draft.emailLoading = true; })
      .addCase(SendExpressAIEmailSuccessAction, (draft) => {
        draft.emailLoading = false;
      })
      .addCase(SendExpressAIEmailFailureAction, (draft) => { draft.emailLoading = false; })
      .addCase(SetDxfEpsgAction, (state, action) => {
        state.epsg = action.payload;
      })
      .addCase(SetEpsgListAction, (state, action) => {
        state.epsgList = action.payload;
      })
      .addCase(SetShowKmzDetectedDialogAction, (state, action) => {
        state.showKmzDetectedDialog = action.payload;
      });
  },
});

export const { reducer } = fileUploadSlice;

export default reducer;
