// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useMemo } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { Paper, Button, Typography, MenuItem, InputBase, Divider, Select, Fade, CircularProgress, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import LoadingSpinner from 'Components/LoadingSpinner';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment-timezone';
import { formatValue, deliveryBusinessDays } from 'Utils/deliverables';
import PDF from 'Features/order/PDF';
import CreateMap from 'Features/map';
import ConfirmDialog from 'Components/ConfirmDialog';
import { GetOrderStatus } from 'Utils/orderStatus';
import { GetAdminProjectsRoute } from 'Utils/routes';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { AdminToggleCadDropdown, ChangeVersion, GetDxfExtentThunk, GetVectorTilesThunk, StopLoading, ToggleNoneVectorTiles } from 'Features/map/mapCommonThunk';
import { ClearFileVersions, GetOrdersThunk, PlaceOrderThunk, ReprocessOrderThunk, SetFileVersionThunk, UpdateOrderStatusThunk } from 'Features/order/orderThunk';
import { GetProjectThunk, SetProjectId } from 'Features/project/projectThunk';
import { DeleteProjectAdminThunk, GetOrgCreditCardsThunk, GetOrgSubscriptionThunk, GetOrgThunk, SetOrgIdThunk } from 'Features/admin/adminThunk';
import { AdminToggleKml, LoadKmlsThunk } from 'Features/kml/kmlThunk';
import { SetDxfExtentAction, SetLayerVisibleAction } from 'Features/map/mapCommonActions';
import { SetOrderSelectedAction } from 'Features/map/mapEditorActions';
import { SetEpsgListAction } from 'Features/fileUpload/fileUploadActions';
import { SetShowAdjustmentMessageAction } from 'Features/order/orderActions';
import { getOrders } from 'Features/order/orderSelectors';
import ConfirmAmountDialog from 'Components/ConfirmAmountDialog';
import OrderCard from './orderCard';
import ProjectDetails from './projectDetails';
import ConvertDialog from './ConvertDialog';
import stylesDef from './styles';

moment.tz.setDefault('America/New_York');

/**
 * Component renders the order details of all orders within a project
 */
export const AdminProject = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const list = useAppSelector((state) => getOrders(state).projectOrdersReverse);
  const { errorMessage } = useAppSelector((state) => state.opsTraining);

  const adjustedAcresTotal = list.reduce((a, b) => a + (b.adjustedAcres || 0), 0);
  const { vectorTileJson } = useAppSelector((state) => state.map.common);
  const { projectId, orgId } = useParams();
  const { fileVersions, dxfFileStatus, anyChangeInFileStatuses, showAdjustmentError } = useAppSelector((state) => state.order);
  const { orgTeams } = useAppSelector((state) => state.account);
  const { org } = useAppSelector((state) => state.admin);
  const { automateSubscription, creditCards } = useAppSelector((state) => state.admin);
  const epsgCode = useAppSelector((state) => state.fileUpload?.epsgList.length && state.fileUpload.epsgList[0]);
  const templates = useAppSelector((state) => state.cadTemplate.list);
  const kmlChange = useAppSelector((state) => state.kml.present.loading);
  const { project } = useAppSelector((state) => state.project);
  const [showLoader, setShowLoader] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [projectFileStatuses, setProjectFileStatuses] = useState([]);
  const [showConfirmDeleteProject, setShowConfirmDeleteProject] = useState(false);
  const [dxfFile, setDxfFile] = useState('None');
  const [dxfFileId, setDxfFileId] = useState('None');
  const [showConfirmStatusDialog, setShowConfirmStatusDialog] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [orderId, setOrderId] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConvertDialog, setShowConvertDialog] = useState(false);
  const [isReprocessSubmitting, setIsReprocessSubmitting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(list[currentIndex]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(null);
  const [converted, setConverted] = useState(false);
  const [invalidFile, setInvalidFile] = useState(true);
  const [validFile, setValidFile] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const layers =
  vectorTileJson &&
  vectorTileJson[currentOrder._id] &&
  vectorTileJson[currentOrder._id][fileVersions[currentOrder._id]];
  const placedOrders = list.some((order) => order.orderStatus > 0);

  useEffect(() => {
    const order = {
      ...list[currentIndex],
      cadFiles: list[currentIndex]?.cadFiles?.map((cad) => ({
        ...cad,
        name: cad.filename ? cad.filename.split('/').pop() : 'original file',
      })),
    };
    setCurrentOrder(order);
  }, [currentIndex, list]);

  const handleDxfDropdownClick = async (siteId: string) => {
    setDxfFileId(siteId);
    if (siteId === 'None') {
      setDxfFile('None');
      dispatch(ToggleNoneVectorTiles());
      dispatch(ChangeVersion());
    } else {
      const file = currentOrder.cadFiles?.find((f) => f._id === siteId)?.filename;
      const fileName = file ? file.split('/').pop() : 'orginal file';
      setDxfFile(fileName);
      dispatch(SetOrderSelectedAction(currentOrder?._id));
      dispatch(SetFileVersionThunk(currentOrder._id, siteId));
      await dispatch(GetVectorTilesThunk());
      dispatch(ChangeVersion());
      dispatch(AdminToggleCadDropdown(currentOrder._id, siteId));
      dispatch(GetDxfExtentThunk(siteId));
    }
    dispatch(SetLayerVisibleAction(null));
  };

  useEffect(() => {
    dispatch(SetOrgIdThunk(orgId));
    dispatch(SetProjectId(projectId));
    dispatch(SetDxfExtentAction(null));
    dispatch(ClearFileVersions());
    dispatch(StopLoading());

    const loadData = async () => {
      setShowLoader(true);
      await dispatch(GetProjectThunk());
      await dispatch(GetOrgThunk(orgId));
      await dispatch(GetOrdersThunk(projectId));
      await dispatch(LoadKmlsThunk());
      setShowLoader(false);
    };

    loadData();
    handleDxfDropdownClick('None');
  }, [projectId]);

  useEffect(() => {
    setShowErrorSnackbar(errorMessage);
    if (errorMessage) setConverted(false);
  }, [errorMessage]);

  const pdfData = useMemo(() => {
    const { addOns, deliverables, referenceId, acreage, kmlLength, creditAcreage, costToCustomer, paidForThisProject, middlePoint, cadTemplate, topoIntervalMajor, topoIntervalMinor,
      orderDate, deliveryDate, bundleName, bundleAcreAdjustmentFactor, anticipatedKickoffDate, deliveryDays, density, deliverySpeed, team, ownerEmail, salesTaxAmount, pricePerFoot } = currentOrder || {};
    const imageryCreditCost = currentOrder?.imageryCreditCost || 0;
    const imageryDollarCost = currentOrder?.imageryDollarCost || 0;
    const rasterizationCreditCost = currentOrder?.rasterizationCreditCost || 0;
    let addOnsInAcres = false;
    // If all add-ons include an acres field, it indicates that the user paid for these add-ons in acres
    if (addOns?.every((addOn) => addOn.hasOwnProperty('acres'))) {
      addOnsInAcres = true;
    }

    const orderTeam = orgTeams?.find((t) => t._id === team);

    const deliverablesCredits = addOns?.reduce((sum, addOn) => {
      if (addOn.addOnType === 'deliverable') {
        if (addOnsInAcres) {
          return sum + addOn.acres;
        }
        return sum + addOn.cost;
      }
      return sum;
    }, 0);
    const deliverablesCreditsString = addOnsInAcres ? `${deliverablesCredits.toFixed(2)} credits` : `$${formatValue(deliverablesCredits)}`;
    const layersCredits = addOns?.reduce((sum, addOn) => {
      if (addOn.addOnType === 'layer') {
        if (addOnsInAcres) {
          return sum + addOn.acres;
        }
        return sum + addOn.cost;
      }
      return sum;
    }, 0);
    const layersCreditsString = addOnsInAcres ? `${layersCredits.toFixed(2)} credits` : `$${formatValue(layersCredits)}`;
    const addOnsCreditsString = addOnsInAcres ? `${(deliverablesCredits + layersCredits + imageryCreditCost + rasterizationCreditCost).toFixed(2)} credits` : `$${formatValue(deliverablesCredits + layersCredits + imageryDollarCost)}`;
    return ({
      type: 'Estimate',
      submitter: ownerEmail || 'N/A',
      projectName: project?.name,
      referenceId,
      team: orderTeam?.name,
      acreage,
      kmlLength,
      middlePoint: `${middlePoint?.place}, ${middlePoint?.region} ${middlePoint?.postcode}`,
      coordinates: `${middlePoint?.longitude.toFixed(5)}, ${middlePoint?.latitude.toFixed(5)}`,
      cadTemplate: templates && templates.find((temp) => temp._id === cadTemplate)?.name,
      crs: epsgCode && `EPSG: ${epsgCode}`,
      topoMajr: topoIntervalMajor,
      topoMinr: topoIntervalMinor,
      businessDays: deliveryDays ? deliveryBusinessDays(deliveryDays) : 'N/A',
      anticipatedKickoffDate,
      orderDate: orderDate ? moment(orderDate).format('MMMM Do YYYY') : 'N/A',
      deliveryDate: deliveryDate ? moment(deliveryDate).format('MMMM Do YYYY') : 'N/A',
      selectedBundle: { name: bundleName, acreAdjustmentFactor: bundleAcreAdjustmentFactor, density, deliverySpeed },
      costPerAcre: org?.costPerAcre,
      bundleDeliverables: deliverables,
      selectedAdditionals: addOns,
      chargedCredits: bundleName === 'A la carte' ? 0 : (creditAcreage - (addOnsInAcres ? layersCredits + deliverablesCredits + imageryCreditCost + rasterizationCreditCost : 0))?.toFixed(2),
      salesTaxAmount,
      totalCreditsCharged: creditAcreage?.toFixed(2),
      deliverablesCreditsString,
      layersCreditsString,
      addOnsCreditsString,
      totalAdditionalCharged: paidForThisProject?.toFixed(2),
      totalCostForProject: costToCustomer?.toFixed(2),
      imageryProvider: project?.imageryRequest?.provider,
      imageryCreditCost,
      rasterizationCreditCost,
      pricePerFoot,
      bundleType: project?.type,
    });
  }, [currentOrder]);

  const prepareProjectFiles = () => {
    if (project) {
      const projectTifFiles = project?.tifFiles.map((tifFile) => ({
        id: tifFile._id,
        epsg: tifFile.epsg,
        name: tifFile.filename.split('/').pop(),
        adminUpload: tifFile.adminUpload,
        type: 'tif',
        status: 'none',
      }));

      const projectLasFiles = project?.lasFiles.filter((lasFile) => lasFile.lasType === 'unclassified').map((lasFile) => ({
        id: lasFile._id,
        epsg: lasFile.epsg,
        name: lasFile.filename.split('/').pop(),
        adminUpload: lasFile.adminUpload,
        type: 'las',
        status: 'none',
      }));

      const projectFiles = [...projectTifFiles, ...projectLasFiles];

      const epsgList: number[] = [];

      projectFiles.forEach((file) => {
        epsgList.push(file.epsg);
        const fileStatus: any = project.fileStatuses?.find((s: any) => s.filename === file.name);
        file.status = fileStatus?.status;
        file.messages = fileStatus?.messages;
      });
      setProjectFileStatuses(projectFiles);

      dispatch(SetEpsgListAction([...new Set(epsgList)]));
    }
  };

  useEffect(() => {
    if (project) {
      prepareProjectFiles();
      dispatch(SetShowAdjustmentMessageAction(false));
    }
  }, [project]);

  useEffect(() => {
    if (currentOrder) {
      dispatch(AdminToggleKml(currentOrder._id));
      handleDxfDropdownClick('None');
      dispatch((SetShowAdjustmentMessageAction(false)));
    }
  }, [currentOrder, projectId, kmlChange]);

  useEffect(() => {
    setInvalidFile(true);
    setValidFile(false);
    const bundleName = currentOrder?.bundleName;
    const tifFiles = projectFileStatuses.filter((file) => file.type === 'tif');
    const validTif = tifFiles.length && tifFiles.every((tif) => tif.epsg > 0 && tif.status !== 'ERROR');
    const validTifExist = tifFiles.length && tifFiles.some((tif) => tif.epsg > 0 && tif.status !== 'ERROR');
    const lasFiles = projectFileStatuses.filter((file) => file.type === 'las');
    const validLas = lasFiles.length && lasFiles.every((las) => las.epsg > 0 && las.status !== 'ERROR');
    const validLasExist = lasFiles.length && lasFiles.some((las) => las.epsg > 0 && las.status !== 'ERROR');

    if ((bundleName === 'Topo' && validLas) ||
    (bundleName === '2D' && validTif) ||
    ((bundleName === '2D + Topo' || bundleName === '3D Feature Elevation') && validLas && validTif) ||
    ((bundleName === 'A la carte' || !bundleName) && (validLas || validTif))
    ) setInvalidFile(false);

    if ((bundleName === 'Topo' && validLasExist) ||
    (bundleName === '2D' && validTifExist) ||
    ((bundleName === '2D + Topo' || bundleName === '3D Feature Elevation') && validLasExist && validTifExist) ||
    (bundleName === 'A la carte' && (validLasExist || validTifExist)) || ( project?.type === 'utilities' && validTifExist)
    ) setValidFile(true);
  }, [currentOrder]);

  const reprocessOrderClick = async (oId: string) => {
    setIsReprocessSubmitting(true);
    await dispatch(ReprocessOrderThunk(oId));
    dispatch(GetOrdersThunk(projectId));
    setIsReprocessSubmitting(false);
  };

  const updateStatusClick = (oId: string, sid: string) => {
    setOrderId(oId);
    setOrderStatus(sid);
    setShowConfirmStatusDialog(true);
  };

  const onOkStatusUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmStatusDialog(false);
    dispatch(UpdateOrderStatusThunk(orderId, orderStatus));
    e.stopPropagation();
  };

  const onCancelStatusUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmStatusDialog(false);
    setOrderStatus(null);
    e.stopPropagation();
  };

  const adjustOrderCreditsClick = (oId: string, orId: string) => {
    setShowConfirmDialog(true);
    setOrderId(oId);
  };

  const placeOrderClick = async () => {
    setIsSubmitting(true);
    await dispatch(PlaceOrderThunk());
    dispatch(GetOrdersThunk(projectId));
    setIsSubmitting(false);
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    e.stopPropagation();
  };

  const onOkDeleteProject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    dispatch(DeleteProjectAdminThunk(projectId));
    navigate(GetAdminProjectsRoute(orgId));
    e.stopPropagation();
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    e.stopPropagation();
  };

  const onCancelAmount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    e.stopPropagation();
  };

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const handleConvert = () => {
    setConverted(true);
    setShowSnackbar(true);
  };

  const handleError = () => {
    setShowSnackbar(false);
    setConverted(false);
    setShowErrorSnackbar(null);
  };

  useEffect(() => {
    if (org?.stripeId) {
      dispatch(GetOrgCreditCardsThunk(org.stripeId));
    }
    if (!automateSubscription) {
      dispatch(GetOrgSubscriptionThunk(orgId));
    }
  }, []);

  // useEffect for checking if the order is disabled or not
  useEffect(() => {
    const checkDisabled = () => {
      return isSubmitting || // We check if the order is being submitted
        !currentOrder?.boundaryFile || // We check if the order has a boundary file
        invalidFile || // We check if the order has a valid file
        (!(automateSubscription?.automateAcresRemaining > currentOrder?.creditAcreage) && creditCards.length === 0) || // We check if the organization has enough credits to process the order or a credit card on file
        !currentOrder?.isEstimate || // We check if the order has an estimate
        (project?.imageryRequest && !project.imageryRequest.notificationSent); // We check if the imagery request and the notification has been sent
    };

    setIsDisabled(checkDisabled());
  }, [isSubmitting, currentOrder, invalidFile, automateSubscription, creditCards, project]);

  return (
    <LoadingSpinner loading={showLoader}>
      <Snackbar open={showSnackbar && !showErrorSnackbar} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success" color="info">TRAINING PROJECT CONVERSION STARTED</Alert>
      </Snackbar>
      <Snackbar open={!!showErrorSnackbar} autoHideDuration={10000} onClose={handleError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" color="error">{errorMessage}</Alert>
      </Snackbar>
      <Box sx={styles.pageWrapper}>
        <Paper sx={styles.headerWrapper}>
          <Box sx={styles.leftSide}>
            <Box sx={styles.sideTitle}>
              <RouterLink to={GetAdminProjectsRoute(orgId)} style={styles.routerLink} title="Back to Projects">
                <KeyboardArrowLeftIcon />
                <Typography variant="subtitle2">Back</Typography>
              </RouterLink>
              <Typography variant="h3" sx={styles.dropdownText} color="textPrimary">
                {project?.name}
              </Typography>
              <Typography>
                {' '}
                {project?.description}
              </Typography>

              {currentOrder?.isEstimate && (
                <Box sx={styles.estimateContainer}>
                  <InfoIcon sx={styles.icon} fontSize="small" />
                  <Typography>
                    {' '}
                    Estimate
                  </Typography>
                </Box>
              )}
              {
                ((project?.training || converted) && !showErrorSnackbar) && (
                  <Typography variant="h6" sx={styles.orderNumber}>Converted to Training Standard</Typography>
                )
              }
            </Box>
          </Box>
          <Box sx={styles.rightSide}>
            <Box sx={styles.headerTitle}>
              <Typography>{`Project Adjusted Credits: ${adjustedAcresTotal?.toFixed(2)}`}</Typography>
              {currentOrder?.isEstimate && (
                <Box sx={styles.estimateButtonContainer}>
                  <Divider orientation="vertical" sx={styles.verticalDivider} flexItem />
                  <PDFDownloadLink document={<PDF data={pdfData} />} fileName={`${pdfData?.projectName}_estimate.pdf`} sx={styles.pdfButton}>
                    {({ blob, url, loading }) =>
                      (loading ? <CircularProgress size={24} sx={styles.buttonProgress} /> : (
                        <Box
                          sx={styles.downloadText}
                          onClick={() => {
                            window?.pendo?.track('Download Estimate', { projectId: currentOrder?.ownerProject });
                          }}
                        >
                          DOWNLOAD
                        </Box>
                      ))}
                  </PDFDownloadLink>
                </Box>
              )}
            </Box>
            <Box sx={styles.headerTitle}>
              {!placedOrders && (
                <Box sx={styles.deleteProjectButton}>
                  <Divider orientation="vertical" sx={styles.verticalDividerDeleteButton} flexItem />
                  <Button sx={styles.deleteButtonText} onClick={() => setShowConfirmDeleteProject(true)}>Delete</Button>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
        <Box sx={styles.bodyWrapper}>
          <ProjectDetails
            isEstimate={currentOrder?.isEstimate}
            list={list}
            siteId={dxfFileId}
            currentIndex={currentIndex}
            layers={layers}
            imageryRequest={project?.imageryRequest}
            rasterRequest={project?.rasterRequest}
            projectFileStatuses={projectFileStatuses}
            selectIndex={setCurrentIndex}
          />
          <Box sx={styles.cardWrapper}>
            {showAdjustmentError && (
              <Box sx={styles.adjustmentErrorPaper}>
                <Paper sx={styles.errorPaper}>
                  <Box sx={styles.adjustmentErrorTitle}>
                    <ErrorIcon sx={styles.errorIcon} />
                    <Typography sx={styles.errorTitleText} variant="h5">Out of Credits!</Typography>
                  </Box>
                  <Box sx={styles.adjustmentErrorBody}>
                    <Typography sx={styles.errorBodyText}>
                      This order could not be adjusted because the account does not have sufficient credits for the adjustment you are attempting to make.
                      Please add more credits to their account or have them resubmit the order and pay for the overages with their credit card.
                    </Typography>
                    <Button variant="text" color="primary" onClick={() => SetShowAdjustmentMessageAction(false)}>OK</Button>
                  </Box>
                </Paper>
              </Box>
            )}
            <Paper sx={styles.dropdownMap}>
              {currentOrder && (
                <Box sx={styles.dropdownText}>
                  <Box sx={styles.leftSide}>
                    <Typography variant="h2" sx={styles.orderNumber}>{`Order ${currentIndex + 1}`}</Typography>
                    <Button
                      color="primary"
                      onClick={() => { adjustOrderCreditsClick(currentOrder._id, currentOrder.ownerOrganization); }}
                    >
                      Adjust Credits
                    </Button>
                    { ((!project?.training && !converted) || showErrorSnackbar) && (
                      <Button
                        color="primary"
                        onClick={() => { setShowConvertDialog(true); }}
                      >
                        Convert
                      </Button>
                    )}
                    {(currentOrder.orderStatus === 1 || currentOrder.orderStatus === 3) && (
                      <Button
                        color="primary"
                        disabled={isReprocessSubmitting || !currentOrder.boundaryFile || invalidFile}
                        onClick={() => reprocessOrderClick(currentOrder._id)}
                      >
                        <Fade in={isReprocessSubmitting}>
                          <CircularProgress size={24} sx={styles.buttonProgress} />
                        </Fade>
                        <Fade in={!isReprocessSubmitting}>
                          <span>Re-try Processing</span>
                        </Fade>
                      </Button>
                    )}

                    {(currentOrder.orderStatus === 0) && (
                      <Button
                        color="primary"
                        onClick={placeOrderClick}
                        disabled={isDisabled}
                      >
                        <Fade in={isSubmitting}>
                          <CircularProgress size={24} sx={styles.buttonProgress} />
                        </Fade>
                        <Fade in={!isSubmitting}>
                          <span>start processing</span>
                        </Fade>
                      </Button>
                    )}
                  </Box>
                  <Typography variant="h6" sx={styles.statusText} color="textPrimary">
                    Status:
                   <Select
                      variant="outlined" 
                      input={<InputBase style={{ width: '200px' }} />}
                      sx={styles.statusText}
                      value={currentOrder.orderStatus}
                      onChange={(ev) => { updateStatusClick(currentOrder._id, ev.target.value); }}
                      styles={{ root: styles.select }}
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((status) =>
                        <MenuItem sx={styles.select} 
                        // disable order status canceled when charge id(it only exists when credit card was used to submit the order) exists
                        // disable all order status when order status is 8
                        // disable cancel when order status is 0 and selected status is 8
                        disabled={(status === 8 && currentOrder.chargeId) || currentOrder.orderStatus === 8 || (currentOrder.orderStatus === 0 && status === 8)}
                         value={status}>
                          {GetOrderStatus(status)}</MenuItem>)}
                    </Select>
                  </Typography>
                </Box>
              )}
              <Divider />
              <Box sx={styles.dropdownText}>
                <Typography variant="h6" sx={styles.dropdownText} color="textPrimary">
                  DXF:
                  <Select
                    variant="outlined"
                    input={<InputBase style={{ width: '350px' }} />}
                    sx={styles.dropdownText}
                    defaultValue=""
                    value={dxfFile}
                    renderValue={(dxfFile) => <>{dxfFile}</>}
                    disabled={list.map((order) => order.cadFiles).length === 0}
                    onChange={(e) => handleDxfDropdownClick(e.target.value)}
                  >
                    <MenuItem value="None"> None </MenuItem>
                    {currentOrder?.cadFiles && currentOrder.cadFiles.map((cadFile) => (
                      <MenuItem value={`${cadFile._id}`}>
                        {cadFile.filename ? cadFile.filename.split('/')[cadFile.filename.split('/').length - 1] : 'original file'}
                      </MenuItem>
                    ))}
                  </Select>
                </Typography>
                <Typography variant="h6" sx={styles.dropdownText} color="textPrimary">
                  KML:
                  {' '}
                  {currentOrder?.boundaryFile ? <Typography>{currentOrder.boundaryFile.split('/').pop()}</Typography> : 'NONE'}
                </Typography>
              </Box>
            </Paper>
            <Box sx={styles.mapWrapper}>
              <CreateMap mode="layers" />
            </Box>
            <Box sx={styles.orderWrapper}>
              {currentOrder && <OrderCard order={currentOrder} projectId={projectId} validFile={validFile} />}
            </Box>
          </Box>
          <ConfirmDialog
            showDialog={showConfirmDeleteProject}
            onOk={onOkDeleteProject}
            onCancel={onCancel}
            contentText="Selected project will be deleted"
          />
          <ConfirmAmountDialog
            showDialog={showConfirmDialog}
            onOk={onOk}
            onCancel={onCancelAmount}
            confirmAmount={+(currentOrder?.creditAcreage?.toFixed(2))}
            orderId={orderId}
            orgId={orgId}
            projectId={projectId}
          />
          <ConfirmDialog
            showDialog={showConfirmStatusDialog}
            onOk={onOkStatusUpdate}
            onCancel={onCancelStatusUpdate}
            contentText={orderStatus === 7 ? 'Are you sure you want to change the status to done? Client will be notified by email.' : `Order status will be changed from ${GetOrderStatus(currentOrder?.orderStatus)} to ${GetOrderStatus(orderStatus)}.`}
          />
          <ConvertDialog
            orderId={currentOrder?._id}
            showDialog={showConvertDialog}
            handleConvert={handleConvert}
            onCloseModal={() => setShowConvertDialog(false)}
          />
        </Box>

      </Box>
    </LoadingSpinner>
  );
};

export default AdminProject;
