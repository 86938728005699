// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material/';
import { useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import { SetMultipleGeometriesUploadAction } from 'Features/kml/kmlActions';
import { ShowUploadSingleFileDialogAction } from 'Features/fileUpload/fileUploadActions';
import { Clear } from 'Features/map/mapDrawThunk';
import stylesDef from './styles';

export const MultipleGeometriesDialog = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const { multipleGeometriesUpload } = useAppSelector((state: RootState) => state.kml.present);

  const onClose = () => {
    dispatch(SetMultipleGeometriesUploadAction(false));
  };

  const onUploadClick = () => {
    dispatch(Clear());
    dispatch(ShowUploadSingleFileDialogAction());
    dispatch(SetMultipleGeometriesUploadAction(false));
  };

  const onProceed = () => {
    dispatch(SetMultipleGeometriesUploadAction(false));
  };

  return (
    <Dialog
      open={multipleGeometriesUpload}
      onClose={onClose}
    >
      <Typography variant="h3" sx={styles.title}>Multiple Geometries Detected</Typography>
      <DialogContent>
        <Typography>
          The file you’re attempting to upload contains multiple geometries. We can display just the polygons within this KML or feel free to upload another file.
        </Typography>
      </DialogContent>
      <DialogActions sx={styles.buttons}>
        <Button sx={styles.cancelButton} onClick={onUploadClick}>UPLOAD A KML</Button>
        <Button sx={styles.okButton} onClick={onProceed}>PROCEED</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleGeometriesDialog;
