// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const mimeTypes: {
    [key: string]: string;
  } = {
    '.kml': 'application/vnd.google-earth.kml+xml',
    '.kmz': 'application/vnd.google-earth.kmz',
    '.tif': 'image/tiff',
    '.tiff': 'image/tiff',
    '.laz': 'application/octet-stream',
    '.las': 'application/octet-stream',
    '.dxf': 'image/vnd.dxf',
    '.xml': 'application/xml',
    '.zip': 'application/zip',
    '.shp': 'application/octet-stream',
    '.dwg': 'application/octet-stream',
    '.dgn': 'application/octet-stream',
    '.dem': 'application/octet-stream',
    '.dtm': 'application/octet-stream',
  };

const createAcceptedExtensionsDict = (acceptedExtensions: string[]) => {
  const acceptedExtensionsDict: {
        [key: string]: string[];
      } = {};
  acceptedExtensions.forEach((extension: string) => {
    const mimeType = mimeTypes[extension];
    if (mimeType) {
      if (acceptedExtensionsDict[mimeType]) {
        acceptedExtensionsDict[mimeType].push(extension);
      } else {
        acceptedExtensionsDict[mimeType] = [extension];
      }
    }
  });
  return acceptedExtensionsDict;
};

export default createAcceptedExtensionsDict;
