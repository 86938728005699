// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CreateMap from 'Features/map';
import { useAppDispatch, useAppSelector } from 'Hooks';
import LoadingSpinner from 'Components/LoadingSpinner';
import UploadKmlDialog from 'Components/FileUploadForm/SingleFileUploadForm';
import EpsgProjection from 'Components/EpsgProjection';
import { GetProjectThunk, SetFileStatusThunk } from './projectThunk';
import { SetProjectIdAction, ResetEpsgProjectionsAction, SetShowKmlToolbarAction, SetRasterizationSelectedAction } from './projectActions';
import { GetOrdersThunk, CreateOrderThunk, SaveEstimateThunk } from '../order/orderThunk';
import { SetShowBufferInfoAction, SetBufferSizeAction, ResetBufferStateAction, ResetKmlAction } from '../kml/kmlActions';
import { ResetKmlDrawAction } from '../map/mapDrawActions';
import { ResetProjectTifInfoAction, ResetProjectLasInfoAction, ClearTileJsonAction } from '../map/mapCommonActions';
import { LoadKmlsThunk } from '../kml/kmlThunk';
import KmlBufferDialog from './KmlBufferDialog';
import KmlToolBar from './KmlToolBar';
import Sidebar from './Sidebar';
import stylesDef from './styles';
import MultipleGeometriesDialog from './MultipleGeometriesDialog';
import KmzDialog from './KmzDialog';

const Project = () => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();

  const resources = useAppSelector((state) => state.auth.resources);
  const opsTrainer = 'opsTrainer' in resources;

  const showKmlToolBar = useAppSelector((state) => state.project.showKmlToolBar);

  const [loading, setLoading] = useState(true);
  const { customCheckoutForm } = useFlags();
  const { id } = useParams();

  const onLoad = async () => {
    const projectId = id;

    dispatch(SetProjectIdAction(projectId));
    // Always get orders in a project
    // eslint-disable-next-line no-unused-expressions
    const projectOrders = await dispatch(GetOrdersThunk(projectId));
    await dispatch(GetProjectThunk());

    // Get active order from the project orders and create one only if it does not exist
    const activeOrder = projectOrders?.data?.find((o: IOrder) => o.ownerProject === projectId && o.orderStatus === 0);

    if (activeOrder?.rasterizationCreditCost) {
      dispatch(SetRasterizationSelectedAction(true));
    }

    if (!activeOrder && !opsTrainer) {
      await dispatch(CreateOrderThunk(projectId));
    }

    if (activeOrder?.bundleId && !customCheckoutForm && activeOrder?.isEstimate && !activeOrder.rasterizationCreditCost) {
      dispatch(SaveEstimateThunk(activeOrder.cadTemplate, null, activeOrder, null, activeOrder.pricePerFoot, true, false));
    }

    if (activeOrder?.boundaryFile) {
      const activeKmlName = activeOrder.boundaryFile.split('/').pop();
      if (activeKmlName.includes('buffered')) {
        dispatch(SetShowKmlToolbarAction(true));
        dispatch(SetShowBufferInfoAction(true));
        if (activeKmlName.includes('road_network_buffered.kml')) {
          const match = activeKmlName.match(/_(\d+)feet_/);
          const bufferSize = match ? parseInt(match[1], 10) : null;
          dispatch(SetBufferSizeAction(bufferSize));
        } else {
          const bufferSize = activeKmlName.split('_').slice(-2, -1)[0].slice(0, -4);
          dispatch(SetBufferSizeAction(parseFloat(bufferSize)));
        }
      }
    }

    await dispatch(LoadKmlsThunk());

    setLoading(false);
    // SetShowBusinessDaysAction(true);
  };

  useEffect(() => {
    onLoad();

    return () => {
      dispatch(SetProjectIdAction(null));
      dispatch(SetFileStatusThunk('reset'));
      // ResetEditorStateAction();
      dispatch(ResetKmlDrawAction());
      dispatch(ResetProjectTifInfoAction());
      dispatch(ResetProjectLasInfoAction());
      dispatch(ResetBufferStateAction());
      dispatch(SetShowKmlToolbarAction(false));
      dispatch(SetRasterizationSelectedAction(false));
      dispatch(ResetEpsgProjectionsAction());
      dispatch(ResetKmlAction());
      dispatch(ClearTileJsonAction('vector'));
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      const intervalId = setInterval(async () => {
        const response = await dispatch(GetProjectThunk());
        // stop polling when get project action failed(e.g., when the project no longer exists)
        if (!response.success) {
          clearInterval(intervalId);
        }
      }, 3000);
    }
  }, [loading]);

  return (
    <LoadingSpinner loading={loading}>
      <Sidebar />
      <Box sx={styles.mapWrapper} id="uploadKmlFormContainer">
        {showKmlToolBar && <KmlToolBar />}
        <CreateMap mode="details" />
        <EpsgProjection />
      </Box>
      <KmlBufferDialog />
      <MultipleGeometriesDialog />
      <KmzDialog />
      <UploadKmlDialog containerId="uploadKmlFormContainer" fileExtension={['.kml', '.kmz']} adminOutputUpload={false} />
    </LoadingSpinner>
  );
};

export default Project;
